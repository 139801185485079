import { useIsSsr } from "../hooks"

const BrowserOnly = ({ render, ssrPlaceholder = null }) => {
  const isSsr = useIsSsr()

  if (isSsr) {
    return ssrPlaceholder
  }

  return render()
}

export default BrowserOnly
