import React from "react"
import { graphql } from "gatsby"
import { DiscussionEmbed } from "disqus-react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BrowserOnly from "../components/BrowserOnly"

const FeedbackPage = ({ data, location }) => (
  <Layout mainClassName="feedback-page">
    <SEO
      title="Feedback"
      description="Give your feedback about YouTube Video Finder project, report issues or request new features."
      pathname={location.pathname}
    />
    <section className="feedback">
      <div className="inner">
        <p className="text__l">
          If you have something on your mind, feel free to share it here or via{" "}
          <a href={`mailto:${data.site?.siteMetadata?.contactEmail}`}>e-mail</a>
        </p>
        <BrowserOnly
          render={() => (
            <DiscussionEmbed
              shortname="youtubevideofinder"
              config={{
                url: window.location.href,
                identifier: "feedback",
                title: "Feedback",
                language: "en",
              }}
            />
          )}
        />
      </div>
    </section>
  </Layout>
)

export default FeedbackPage

export const query = graphql`
  query FeedbackPage {
    site {
      siteMetadata {
        contactEmail
      }
    }
  }
`
